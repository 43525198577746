import React, { Component } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { closeSignupModal, openLoginModal } from '../../actions/modalActions';
import messages from '../../locale/messages';
import Link from '../Link';
import RegisterForm from '../RegisterForm';
import SocialLogin from '../SocialLogin';

import s from './SignupModal.module.css';

class SignupModal extends Component {
  static propTypes = {
    closeSignupModal: PropTypes.func,
    signupModal: PropTypes.bool,
    openLoginModal: PropTypes.func,
    formatMessage: PropTypes.func,
    signUpMsg: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      signupModalStatus: false,
      isFormOpen: false,
    };
    this.openForm = this.openForm.bind(this);
  }

  openForm() {
    this.setState({ isFormOpen: true });
  }

  componentDidMount() {
    const { signupModal } = this.props;
    if (signupModal === true) {
      this.setState({ signupModalStatus: true });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { signupModal } = nextProps;
    if (signupModal === true) {
      this.setState({ signupModalStatus: true });
    } else {
      this.setState({ signupModalStatus: false });
    }
  }

  renderButtons() {
    const signUpMsg = true;
    return (
      <div>
        {/* <SocialLogin signUpMsg={signUpMsg} /> */}
        <strong className={s.lineThrough}>
          <FormattedMessage {...messages.or} />
        </strong>
        <FormGroup className={s.formGroup}>
          <Button
            block
            bsSize="large"
            className={cx(s.button, s.btnPrimary)}
            onClick={() => this.openForm()}
          >
            <FormattedMessage {...messages.signupWithEmail} />
          </Button>
        </FormGroup>
        <FormGroup className={s.formGroup}>
          <Link
            className={cx(s.button, s.btnPrimary, s.fontWeight400)}
            to="/become-a-member"
          >
            <FormattedMessage {...messages.noReferralCode} />
          </Link>
        </FormGroup>
      </div>
    );
  }

  renderForm() {
    const signUpMsg = true;
    let initialValues = {};
    initialValues = {
      intend: 'travel',
    };

    return (
      <div>
        <div className={cx(s.formGroup, s.formSection)}>
          {/* <Col xs={12} sm={12} md={12} lg={12} className={cx(s.noPadding, s.textAlignLeft)}>
            <p className={cx(s.noMargin, s.captionText, s.textAlignCenter)}>
              <FormattedMessage {...messages.signupWith} /> {' '}
              <a href="/login/facebook" className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge)}>
                <FormattedMessage {...messages.facebook} />
              </a> {' '}
              <FormattedMessage {...messages.or} /> {' '}
              <a href="/login/google" className={cx(s.modalCaptionLink, s.modalCaptionLinkLarge)}>
                <FormattedMessage {...messages.google} />
              </a>
            </p>
          </Col> */}
          {/* <SocialLogin signUpMsg={signUpMsg} /> */}
        </div>

        <strong className={s.lineThrough}>
          <FormattedMessage {...messages.or} />
        </strong>

        <RegisterForm initialValues={initialValues} />
      </div>
    );
  }

  render() {
    const { closeSignupModal, openLoginModal } = this.props;
    const { signupModalStatus, isFormOpen } = this.state;

    return (
      <div>
        <Modal
          animation={false}
          dialogClassName={cx(s.signupModalContainer, 'signupModal')}
          onHide={closeSignupModal}
          show={signupModalStatus}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage {...messages.signup} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body bsClass={s.signupModalBody}>
            <div className={s.root}>
              <div className={s.container}>
                {isFormOpen && this.renderForm()}

                {!isFormOpen && this.renderButtons()}

                <p className={s.captionText}>
                  <small>
                    <FormattedMessage {...messages.terms1} />
                    <span>
                      &nbsp;
                      <Link onClick={closeSignupModal} to="/termsService">
                        <FormattedMessage {...messages.termsOfService} />
                      </Link>
                    </span>
                  </small>
                </p>

                <hr className={s.horizontalLineThrough} />

                <div className={cx(s.formGroup, s.formSection)}>
                  <Col
                    className={cx(s.noPadding, s.textAlignLeft, s.smSpace)}
                    lg={8}
                    md={8}
                    sm={8}
                    xs={12}
                  >
                    <a
                      className={cx(
                        s.modalCaptionLink,
                        s.modalCaptionLinkLarge
                      )}
                      href="#"
                      onClick={openLoginModal}
                    >
                      <FormattedMessage {...messages.alreadyHaveAccount} />
                    </a>
                  </Col>
                  <Col
                    className={cx(s.noPadding, s.textAlignRight)}
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                  >
                    <Button
                      bsSize="small"
                      className={cx(s.button, s.btnPrimaryBorder, s.btnSmall)}
                      onClick={openLoginModal}
                    >
                      <FormattedMessage {...messages.login} />
                    </Button>
                  </Col>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  signupModal: state.modalStatus.isSignupModalOpen,
});

const mapDispatch = {
  closeSignupModal,
  openLoginModal,
};

export default connect(mapState, mapDispatch)(SignupModal);
